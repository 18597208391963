import NProgress from 'nprogress';
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid';

const state = {
  processList: []
  
}

const getters = {
  processList: state => state.processList  
}

const actions = {
  async getProcessList(context) {
    NProgress.start();
    return axios.get(`/process-list/for-customers`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_PROCESS_LIST', resData.data);
      }
    })
  },  
  async addAllowedProcess(context, payload) {
    NProgress.start();
    let process = Object.assign({}, payload);
    try {
      const { data } = await axios.put(`/process-list/allow`, process);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      context.commit('UPDATE_ADDED_PROCESS', { ...data.data });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async deleteAllowedProcess(context, payload) {
    NProgress.start();
    context.commit('DELETE_ALLOWED_PROCESS', payload);
    try {
      const { data } = await axios.delete(`/process-list`, { data: { 'entity_id': payload.entity_id } });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  }
}

const mutations = {
  SET_PROCESS_LIST(state, data) {
    state.processList = data;
    NProgress.done();
  },
  ADD_ALLOWED_PROCESS(state, data) {
    state.processList.push(data);
    NProgress.done();
  },
  UPDATE_ADDED_PROCESS(state, data) {
    let index = state.processList.findIndex((e) => e.entity_id == data.entity_id);
    if (index >= 0) {
      state.processList.splice(index, 1, data);
    } else {
      state.processList.push(data)
    }
    NProgress.done();
  },
  DELETE_ALLOWED_PROCESS(state, payload) {
    let index = null;
    if(!payload.parentId) {
      index = state.processList.findIndex(e => e.entity_id == payload.entity_id);
      if(index > -1) {
        state.processList.splice(index, 1);
      }
    } else {
      let dataIndex = state.processList.findIndex(e => e.entity_id == payload.parentId);
      index = state.processList[dataIndex].children.findIndex(e => e.entity_id == payload.entity_id);
      if(index > -1) {
        state.processList[dataIndex].children.splice(index, 1);
      }
    }
    NProgress.done();
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
