import Vue from 'vue';
import Vuex from 'vuex';
import loginModule from './login';
import organization from './organization';
import user from './user';
import apiKey from './apiKey';
import agent from './agent';
import alert from './alert';
import authModule from './authentication';
import processList from './processList';
import processListHidden from './processListHidden';
import commands from './commands';
import crashLogs from './crashLogs';
import suspiciousProcess from './suspiciousProcess';
import mfa from './mfa';
import performance from './performance';
import auditLogs from './auditLogs';
import logAlerts from './logAlerts';
import wipersnareAgent from './wipersnareAgent';
import wipersnareAlert from './wipersnareAlert';
import wipersnareCommands from './wipersnareCommands';
import wipersnareCrashLogs from './wipersnareCrashLogs';
import wipersnarePerformance from './wipersnarePerformance';
import wipersnareProcessList from './wipersnareProcessList';
import wipersnareSuspiciousProcess from './wipersnareSuspiciousProcess';
import MessagingService from '@/services/messagingService';

import { vm } from '../main';


const messagingService = new MessagingService()

Vue.use(Vuex);


function handleObjectCreate(context, data) {
  if (!data) {
    return
  }
  Object.keys(data).forEach((objKey) => {
    if (objKey == 'CrashLogEntry') {
      let crashLogEntry = data[objKey]
      context.commit('UPDATE_NEW_CRASH_LOG', crashLogEntry.agent_id, { root: true })
      context.commit('ADD_CRASH_LOG', crashLogEntry, { root: true })
    } else if (objKey == 'SuspiciousProcessEntry') {
      let suspiciousProcessEntry = data[objKey]
      context.commit('UPDATE_NEW_SUSPICIOUS_PROCESS', suspiciousProcessEntry.agent_id, { root: true })
      context.commit('ADD_SUSPICIOUS_PROCESS', suspiciousProcessEntry, { root: true })
    } else if (objKey == 'Poll') {
      let pollObj = data[objKey]
      context.commit('UPDATE_POLL', pollObj, { root: true })
    } else if (objKey == 'Agent') {
      let agentObj = data[objKey]
      context.commit('UPSERT_AGENT', agentObj, { root: true })
    } else if (objKey == 'PerformanceMetrics') {
      let { metricsMounted } = context.rootState.performance;
      if (metricsMounted) {
        let performanceMetricObj = data[objKey]
        context.commit('ADD_METRICS', performanceMetricObj, { root: true })
      }
    }

    // Wipersnare
    if (objKey == 'WipersnareCrashLogEntry') {
      let crashLogEntry = data[objKey]
      context.commit('UPDATE_NEW_WIPERSNARE_CRASH_LOG', crashLogEntry.agent_id, { root: true })
      context.commit('ADD_WIPERSNARE_CRASH_LOG', crashLogEntry, { root: true })
    } else if (objKey == 'WipersnareSuspiciousProcessEntry') {
      let suspiciousProcessEntry = data[objKey]
      context.commit('UPDATE_NEW_WIPERSNARE_SUSPICIOUS_PROCESS', suspiciousProcessEntry.agent_id, { root: true })
      context.commit('ADD_WIPERSNARE_SUSPICIOUS_PROCESS', suspiciousProcessEntry, { root: true })
    } else if (objKey == 'WipersnarePoll') {
      let pollObj = data[objKey]
      context.commit('UPDATE_WIPERSNARE_POLL', pollObj, { root: true })
    } else if (objKey == 'WipersnareAgent') {
      let agentObj = data[objKey]
      context.commit('UPSERT_WIPERSNARE_AGENT', agentObj, { root: true })
    } else if (objKey == 'WipersnarePerformanceMetrics') {
      let { wipersnareMetricsMounted } = context.rootState.wipersnarePerformance;
      if (wipersnareMetricsMounted) {
        let performanceMetricObj = data[objKey]
        context.commit('ADD_WIPERSNARE_METRICS', performanceMetricObj, { root: true })
      }
    }
  })
}


function handleObjectUpdate(context, data) {
  if (!data) {
    return
  }
  Object.keys(data).forEach((objKey) => {
    if (objKey == 'Agent') {
      let agentObj = data[objKey]
      context.commit('UPSERT_AGENT', agentObj, { root: true })
    } else if (objKey == 'WipersnareAgent') {
      let agentObj = data[objKey]
      context.commit('UPSERT_WIPERSNARE_AGENT', agentObj, { root: true })
    }

    if (objKey == 'Poll') {
      let pollObj = data[objKey]
      context.commit('UPDATE_POLL', pollObj, { root: true })
    } else if (objKey == 'WipersnarePoll') {
      let pollObj = data[objKey]
      context.commit('UPDATE_WIPERSNARE_POLL', pollObj, { root: true })
    }
  })
}


function setOrganizationListeners(context, organizationId) {
  if (!organizationId) {
    return
  }
  console.log("Setting listeners for organization " + organizationId)

  messagingService.removeAllListeners()

  messagingService.setListeners(context, organizationId, handleObjectCreate, handleObjectUpdate)
}


export default new Vuex.Store({
  state: {
    appDrawer: false,
    isLoading: false,
    currentOrganization: {}
  },
  mutations: {
    toggleAppDrawer(state) {
      if (state.appDrawer) {
        state.appDrawer = false;
      } else {
        state.appDrawer = true;
      }
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    SET_CURRENT_ORGANIZATION (state, organization) {
      state.currentOrganization = organization
    },
  },
  actions: {
    showMessage({ commit }, payload) {
      return vm.$notify({
        type: payload.messageType,
        title: payload.messageTitle,
        text: payload.message,
      });
    },
    loadCurrentOrganization (context) {
            const organizations = context.state.organization.organizationList

      let organization = {}
      const organizationString = localStorage.getItem('ransomsnare-currentOrganization')
      if (organizationString) {
        try {
          organization = JSON.parse(organizationString)
        } catch (ex) {
          console.error(ex)
        }
      }

      if (organization.entity_id) {
        organization = organizations.find(org => org.entity_id === organization.entity_id)
        if (!organization) {
          organization = {}
        }
      }

      if (!organization.entity_id/* && organizations.length === 1 */) {
        organization = organizations[0]
      }

      context.dispatch('changeCurrentOrganization', organization)
    },

    async changeCurrentOrganization (context, organization) {
      localStorage.setItem('ransomsnare-currentOrganization', JSON.stringify(organization))
      context.commit('SET_CURRENT_ORGANIZATION', organization)
      if (organization.entity_id) {
        if (vm.$route.name == 'Users') {
          context.dispatch('getUsers')
        } else if (vm.$route.name == 'ApiKeys' || vm.$route.name == 'Downloads') {
          context.dispatch('getApiKeys')
        } else if (vm.$route.name == 'Alerts') {
          context.dispatch('getProcessList')  
        } else if (vm.$route.name == 'Audit Trail Logs') {
          context.dispatch('getLatestAuditLogs')
        } else if (vm.$route.name == 'Agents') {
          context.dispatch('getAgents')
        }

        setOrganizationListeners(context, organization.entity_id)
      }
    }
  },
  getters: {
    appDrawer: state => state.appDrawer,
    isLoading: state => state.isLoading,
    currentOrganization: state => state.currentOrganization
  },
  modules: {
    login: loginModule,
    auth: authModule,
    organization,
    user,
    apiKey,
    agent,
    alert,
    wipersnareAgent,
    wipersnareAlert,
    wipersnareCommands,
    wipersnareCrashLogs,
    wipersnarePerformance,
    wipersnareProcessList,
    wipersnareSuspiciousProcess,
    processList,
    processListHidden,
    commands,
    crashLogs,
    suspiciousProcess,
    mfa,
    performance,
    auditLogs,
    logAlerts
  }
})
