import NProgress from 'nprogress';
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid';

const state = {
  organizationList: [],
  adminOrganizationList: []
}

const getters = {
  organizationList: state => state.organizationList,
  adminOrganizationList: state => state.adminOrganizationList
}

const actions = {
  async createOrUpdateOrganizationLogo(context, payload) {
    NProgress.start();
    try {
      const { data } = await axios.post(`/organization/logo`, payload);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return data.data;
    } catch(e) {
      console.log(e);
    } finally {
      NProgress.done();
    }
  },
  
  async getOrganizationLogo(context, organizationId) {
    NProgress.start();
    try {
      const { data } = await axios.get(`/organization/logo`, { params: { organization_id: organizationId } });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      return data.data;
    } catch(e) {
      console.log(e);
    } finally {
      NProgress.done();
    }
  },
  
  async deleteOrganizationLogo(context, organizationId) {
    NProgress.start();
    try {
      const { data } = await axios.delete(`/organization/logo`, { params: { organization_id: organizationId } });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
    } catch(e) {
      console.log(e);
    } finally {
      NProgress.done();
    }
  },
  async getAdminOrganizations(context) {
    NProgress.start()
    return axios.get(`/organization/all`).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_ADMIN_ORGANIZATION_LIST', resData.data)
      }
    })
  },
  async getOrganizations(context) {
    NProgress.start()
    return axios.get(`/user/organizations`).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_ORGANIZATION_LIST', resData.data)
      }
    })
  },
  async addOrganization(context, payload) {
    NProgress.start();
    let org = Object.assign({}, payload);
    org.tempId = 'ORG_'+uuidv4();
    org.name = payload.name;
    context.commit('ADD_ORGANIZATION', org);
    if(payload.isAdmin) {
      context.commit('ADD_ADMIN_ORGANIZATION', org);
    }
    try {
      const { data } = await axios.post(`/organization`, {
        organization_name: payload.name, 
        organization_type: payload.organization_type,
        managed_by: payload.managed_by,
        first_name: payload.first_name, 
        last_name: payload.last_name, 
        email: payload.contact_email,
        contact_phone: payload.contact_phone,
        slack_hook_url:payload.slack_hook_url,

      });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      context.commit('UPDATE_ADDED_ORGANIZATION', {org: data.data, tempId: org.tempId});
      if(payload.isAdmin) {
        context.commit('UPDATE_ADDED_ADMIN_ORGANIZATION', {org: data.data, tempId: org.tempId});
      }
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async updateOrganization(context, payload) {
    NProgress.start()
    try {
      const { data } = await axios.put(`/organization`, payload);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      if (typeof data.data.global_agent_settings === 'string')
        data.data.global_agent_settings = JSON.parse(data.data.global_agent_settings);
      context.commit('UPDATE_ORGANIZATION', { ...data.data });
      if(payload.isAdmin) {
        context.commit('UPDATE_ADMIN_ORGANIZATION', { ...data.data });
      }
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async deleteOrganization(context, payload) {
    NProgress.start()
    context.commit('DELETE_ORGANIZATION', payload)
    try {
      const { data } = await axios.delete(`/organization/${payload.entity_id}`);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
}

const mutations = {
  SET_ADMIN_ORGANIZATION_LIST(state, data) {
    state.adminOrganizationList = data
    NProgress.done()
  },
  SET_ORGANIZATION_LIST(state, data) {
    state.organizationList = data
    NProgress.done()
  },
  ADD_ORGANIZATION(state, data) {
    state.organizationList.push(data)
    NProgress.done()
  },
  ADD_ADMIN_ORGANIZATION(state, data) {
    state.adminOrganizationList.push(data)
    NProgress.done()
  },
  UPDATE_ORGANIZATION(state, data) {
    let index = state.organizationList.findIndex((e) => e.entity_id == data.entity_id)
    Object.assign(state.organizationList[index], data);
    NProgress.done()
  },
  UPDATE_ADMIN_ORGANIZATION(state, data) {
    let index = state.adminOrganizationList.findIndex((e) => e.entity_id == data.entity_id)
    Object.assign(state.adminOrganizationList[index], data);
    NProgress.done()
  },
  UPDATE_ADDED_ORGANIZATION(state, data) {
    let index = state.organizationList.findIndex((e) => e.tempId == data.tempId)
    state.organizationList.splice(index, 1, data.org)
    NProgress.done()
  },
  UPDATE_ADDED_ADMIN_ORGANIZATION(state, data) {
    let index = state.adminOrganizationList.findIndex((e) => e.tempId == data.tempId)
    state.adminOrganizationList.splice(index, 1, data.org)
    NProgress.done()
  },
  DELETE_ORGANIZATION(state, payload) {
    let index = state.organizationList.findIndex(e => e.entity_id == payload.entity_id)
    if(index > -1) {
      state.organizationList.splice(index, 1)
    }
    NProgress.done()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
